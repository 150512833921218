// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProductsByPage, getOneProduct } from 'src/api/server/product';

// ----------------------------------------------------------------------

export const fetchProductsData = createAsyncThunk(
  'products/fetchProductData',
  async ({ idUser, config, page, addData }) => {
    const products = await getProductsByPage(idUser, config, page)
    console.info('fetchProductsData products', products)
    // eslint-disable-next-line no-restricted-syntax
    for(const product of products?.products||[]){
      // eslint-disable-next-line no-await-in-loop
      await addData(product)
    }
    return products
  })

export const fetchOneProduct = createAsyncThunk(
  'products/fetchOneProduct',
  async ({ idUser, config, id, addData }) => {
    const product = await getOneProduct(idUser, config, id)
    console.info('fetchOneProduct product', product)
    addData(product)
    return product
  });

const initialState = { products: [], isLoading: false, error: undefined, isValidating: false,
  lastUpdatedDate: undefined, nextPage: 1, fieldsSearchable: [] }

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetProducts: (state, action) => {
      action.payload()
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value
      })
    },
    updateProducts: (state, action) => {
      const { data, addData } = action.payload;
      data.forEach(product => {
        const productFound = state.products.find(prod => prod.id === product?.id);
        if (productFound) {
          Object.entries(product).forEach(([key, value]) => {
            productFound[key] = value;
          });
          addData(product)
        }
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProductsData.pending, state => {
        if (!state.products[0]) {
          state.isLoading = true;
          state.error = undefined;
          state.isValidating = false;
        }
      })
      .addCase(fetchProductsData.fulfilled, (state, action) => {
        const {products, nextPage, fieldsSearchable} = action.payload
        state.isLoading = false
        state.isValidating = true
        state.error = undefined
        state.nextPage = nextPage
        state.fieldsSearchable = fieldsSearchable
        products.forEach(product => {
          const prodFound = state.products.find(prod => prod?.id === product.id);
          if (product.images) product.coverUrl = product.images[0];
          const productToPush = {
            id : product.id,
            sku : product.sku,
            name : product.name,
            price : product.price,
            images : product.images,
            quantity : product.quantity,
            available : product.available,
            coverUrl : product.images && product.images[0],
            colors : [],
            sizes : []
          }
          fieldsSearchable.forEach(field => {
            productToPush[field] = product[field]
          })
          if (!prodFound) {
            productToPush.importedAt = (new Date()).toString()
            productToPush.lastUpdatedDate = (new Date()).toString()
            state.products.push(productToPush);
          }
          else {
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(productToPush)) {
              if (key !== 'importedAt' && key !== 'lastUpdatedDate') {
                prodFound[key] = value;
              }
            }
            prodFound.lastUpdatedDate = (new Date()).toString()
          }
        });
      })
      .addCase(fetchProductsData.rejected, (state, action) => {
        state.isLoading = false;
        state.isValidating = false;
        state.error = action.payload;
      })
      .addCase(fetchOneProduct.fulfilled, (state, action) => {
        const product = action.payload;
        const productFound = state.products.find(prod => prod?.id === product.id);
        const productToPush = {
          id : product.id,
          sku : product.sku,
          name : product.name,
          price : product.price,
          images : product.images,
          quantity : product.quantity,
          available : product.available,
          coverUrl : product.images && product.images[0],
          colors : [],
          sizes : []
        }
        if (!productFound) {
          productToPush.importedAt = (new Date()).toString();
          productToPush.lastUpdatedDate = (new Date()).toString();
          state.products.push(productToPush);
        } else {
          Object.entries(productToPush).forEach(entry => {
            const [key, value] = entry;
            if (key !== 'importedAt' && key !== 'lastUpdatedDate') {
              if (productFound[key] !== value) {
                productFound[key] = value;
              }
            }
          });
          productFound.lastUpdatedDate = (new Date()).toString();
        }
      });
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetProducts,
  updateProducts,
} = slice.actions;
