import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSupplies } from 'src/api/server/user';

// ----------------------------------------------------------------------

export const fetchSuppliesData = createAsyncThunk(
  'supply/fetchSuppliesData',
  async ({ idUser, config }) => {
    const response = await getSupplies(idUser, config)
    const { data } = response
    data.forEach(d => {
      d.lastUpdatedDate = `${new Date()}`
      d.importedAt = `${new Date()}`
      d.products = d.products.map(prd => ({...prd, ...(prd.product||{}), product: undefined}))
    })
    return response.data
  })

const initialState = { allData: [], isLoading: false, error: undefined }

const updSupply = (state, supply) => {
  const supplyFound = state.allData.find(x => x?.id === supply.id)
  if (!supplyFound) {
    const supplyToPush = {
      id : supply.newId || supply.id,
      name : supply.name,
      totalQuantity : supply.totalQuantity,
      description : supply.description,
      createdAt : supply.createdAt,
      suppliedAt : supply.suppliedAt,
      status : supply.status,
      importedAt : (new Date()).toString(),
      lastUpdatedDate : (new Date()).toString(),
      products: supply.products,
      histories: supply.histories
    }
    state.allData.push(supplyToPush)
  }
  else {
    Object.entries(supply).forEach(([key, value]) => {
      supplyFound[key] = value
    })
    supplyFound.lastUpdatedDate = (new Date()).toLocaleString()
    supplyFound.id = supply.newId || supply.id
  }
}

const slice = createSlice({
  name: 'supplies',
  initialState,
  reducers: {
    resetSupplies: (state, _action) => {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    updateSupplies: (state, action) => {
      const data = action.payload;
      data.forEach(supply => {
        updSupply(state, supply)
      });
    },
    removeSupply: (state, action) => {
      state.allData = state.allData.filter(data => data.id !== action.payload)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSuppliesData.pending, state => {
        if (!state.allData[0]) {
          state.isLoading = true
          state.error = undefined
        }
      })
      .addCase(fetchSuppliesData.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = undefined
        state.allData = action.payload
      })
      .addCase(fetchSuppliesData.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
  resetSupplies,
  updateSupplies,
  removeSupply
} = slice.actions
