import { lazy, Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard'
import { AuthUserType } from 'src/auth/types'
// layouts
import DashboardLayout from 'src/layouts/dashboard'
// components
import { LoadingScreen } from 'src/components/loading-screen'

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'))
// ALL PAGES
const allPages = {
  user: {
    list: lazy(() => import('src/pages/dashboard/user/list')),
    account: lazy(() => import('src/pages/dashboard/user/account')),
    config: lazy(() => import('src/pages/dashboard/user/config')),
    new: lazy(() => import('src/pages/dashboard/user/new')),
    role: lazy(() => import('src/pages/dashboard/user/role')),
    register: lazy(() => import('src/pages/dashboard/user/register'))
  },
  customer: {
    list: lazy(() => import('src/pages/dashboard/customer/list')),
    new: lazy(() => import('src/pages/dashboard/customer/new'))
  },
  product: {
    root: lazy(() => import('src/pages/dashboard/product/list')),
    ':id': lazy(() => import('src/pages/dashboard/product/details')),
    'supply/:id': lazy(() => import('src/pages/dashboard/product/supply')),
    'supply': lazy(() => import('src/pages/dashboard/product/supplylist')),
  },
  order: {
    sales: lazy(() => import('src/pages/dashboard/order/sales')),
    invoices: lazy(() => import('src/pages/dashboard/invoice/list')),
    'invoice/:id': lazy(() => import('src/pages/dashboard/invoice/details')),
    'return/:id': lazy(() => import('src/pages/dashboard/invoice/return'))
  }
} as any

// ----------------------------------------------------------------------

export const dashboardRoutes = (user: AuthUserType) => {
  const toReturn = [
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <IndexPage />, index: true }
      ],
    },

    // No match dashboard
    { path: '*', element: <Navigate to="/dashboard" replace /> }
  ] as any[]
  const access = user?.access
  if (access) {
    Object.entries(access).forEach(([key, value]: [string, any]) => {
      const KeyPage = allPages[key][value[0]?.name]
      if (KeyPage) {
        const keyChild = {
          path: key,
          children: [{ element: <KeyPage />, index: true, path: '' }]
        }
        value.forEach(({ name }: { name: string }) => {
          const ValPage = allPages[key][name]
          if (ValPage) keyChild.children.push({
            index: false, path: name !== 'root' && name || '',
            element: <ValPage />
          })
        })
        if (key === 'user' && user?.role === 'Admin')
          keyChild.children.push({ index: false, path: 'config', element: <allPages.user.config /> })
        toReturn[0].children.push(keyChild)
      }
    })
  }
  return toReturn
}
