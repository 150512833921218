const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
      createPassword: `${ROOTS.AUTH}/amplify/create-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      role: `${ROOTS.DASHBOARD}/user/role`,
      register: `${ROOTS.DASHBOARD}/user/register`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      config: `${ROOTS.DASHBOARD}/user/config`,
    },
    customer: {
      root: `${ROOTS.DASHBOARD}/customer`,
      new: `${ROOTS.DASHBOARD}/customer/new`,
      list: `${ROOTS.DASHBOARD}/customer/list`
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      supplyList: `${ROOTS.DASHBOARD}/product/supply`,
      supply: (id: string) => `${ROOTS.DASHBOARD}/product/supply/${id}`,
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      sales: `${ROOTS.DASHBOARD}/order/sales`,
      invoices: `${ROOTS.DASHBOARD}/order/invoices`,
      invoiceDetails: (id: string) => `${ROOTS.DASHBOARD}/order/invoice/${id}`,
      returnDetails: (id: string) => `${ROOTS.DASHBOARD}/order/return/${id}`,
    }
  }
}
