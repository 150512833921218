// utils
import { axiosServerData, NetsuiteInstanceProps } from 'src/utils/axios'
// types
import { IUserItem } from 'src/types/user'
// ----------------------------------------------------------------------
const param = {firstEndPoint: 'user'} as NetsuiteInstanceProps
const initParam = ({ idUser, data, endUrl, lastEndPoint, method }: NetsuiteInstanceProps) => {
  param.data = data
  param.endUrl = endUrl || ''
  param.idUser = idUser
  param.lastEndPoint = lastEndPoint
  param.method = method
  param.headers = {}
}
type DataUser = {
  success: boolean
  data: {
    result: IUserItem
    token: string
  }
  error: any
}
export const createUser = async (data: any, idUser: string) => {
  initParam({data, idUser, lastEndPoint: 'root', method: 'post'} as NetsuiteInstanceProps)
  return await axiosServerData(param) as DataUser
}
export const createCognitoUser = async (data: any, token: string) => {
  initParam({data, lastEndPoint: 'cognito', method: 'post', endUrl: `/${token}`} as
    NetsuiteInstanceProps)
  return await axiosServerData(param) as DataUser
}
export const getAllUsers = (idUser: string) => {
  initParam({idUser, lastEndPoint: 'list', method: 'get'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const getProfile = (idUser: string) => {
  initParam({idUser, lastEndPoint: 'profile', method: 'get'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const updateProfile = (idUser: string, data: any) => {
  initParam({idUser, data, lastEndPoint: 'profile', method: 'patch'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const getAccesses = (idUser: string) => {
  initParam({idUser, lastEndPoint: 'accesses', method: 'get'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const getRoles = (idUser: string) => {
  initParam({idUser, lastEndPoint: 'roles', method: 'get'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const createRole = (idUser: string, data: any) => {
  initParam({idUser, data, lastEndPoint: 'roles', method: 'post'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const updateRole = (idUser: string, idRole: string, data: any) => {
  initParam({idUser, data, lastEndPoint: 'roles', endUrl:`/${idRole}`, method: 'patch'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const verifyCashRegister = (idUser: string) => {
  initParam({idUser, lastEndPoint: 'cashRegister', endUrl:`/verify`, method: 'get'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const loginCashRegister = (idUser: string, idCashRegister: string) => {
  initParam({idUser, lastEndPoint: 'cashRegister', endUrl:`/${idCashRegister}/login`, method: 'get'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const createCashRegister = (idUser: string, data: any) => {
  initParam({idUser, data, lastEndPoint: 'cashRegister', method: 'post'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const updateCashRegister = (idUser: string, idCashRegister: string, data: any) => {
  initParam({idUser, lastEndPoint: 'cashRegister', endUrl:`/${idCashRegister}`, method: 'patch', data} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
export const updateSupply = (idUser: string, config: string, data: any) => {
  initParam({idUser, data, lastEndPoint: 'supply', method: 'post'} as NetsuiteInstanceProps)
  param.headers = { config }
  return axiosServerData(param)
}
export const getSupplies = (idUser: string, config: string) => {
  initParam({idUser, lastEndPoint: 'supply', endUrl:`/list`, method: 'get'} as NetsuiteInstanceProps)
  param.headers = { config }
  return axiosServerData(param)
}
export const updateConfig = (idUser: string, data: any) => {
  data.updateConfig = true
  initParam({idUser, data, lastEndPoint: 'config', method: 'post'} as NetsuiteInstanceProps)
  return axiosServerData(param)
}
