// utils
import { axiosServerData, NetsuiteInstanceProps } from 'src/utils/axios'

// ----------------------------------------------------------------------

const param = {firstEndPoint: 'product'} as NetsuiteInstanceProps

const initParam = () => {
  param.data = undefined
  param.endUrl = ''
  param.headers = {}
}
const getProductsByPage = (idUser: string, config: string, page=1) => {
  initParam()
  param.method = 'get'
  param.idUser = idUser
  param.endUrl = `/${page}?count=100`
  param.lastEndPoint = 'page'
  param.headers = { config }
  return axiosServerData(param)
}
const getOneProduct = async (idUser: string, config: string, id: string) => {
  initParam()
  param.method = 'get'
  param.idUser = idUser
  param.endUrl = `/${id}`
  param.lastEndPoint = 'list'
  param.headers = { config }
  return axiosServerData(param)
}

export { getProductsByPage, getOneProduct }
