import { useEffect } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import MainLayout from 'src/layouts/main'
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global'
// api
import { useGetUsers } from 'src/api/user'
import useSupplies from "src/api/hooks/use-supplies"
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { useAuthContext } from '../../auth/hooks';

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuthContext()
  const { onInitUsers } = useGetUsers()
  const { onInitSupplies } = useSupplies()

  useEffect(() => {
    const accesses = user?.accesses
    if(accesses?.[0]){
      const userKeys = accesses.find((acc: any) => acc.name === 'user')?.keys
      if(userKeys?.[0]){
        if(userKeys.find((key: any) => key.access.name === 'list' && key.accessible))
          onInitUsers(user?.id)
      }
      const productKeys = accesses.find((acc: any) => acc.name === 'product')?.keys
      if(productKeys?.[0]){
        if(productKeys.find((key: any) => key.access.name === 'supply' && key.accessible))
          onInitSupplies({ idUser: user?.id })
      }
    }
  }, [onInitSupplies, onInitUsers, user])

  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes(user),

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
