import { useCallback, useMemo } from 'react';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import {
  resetSupplies,
  updateSupplies,
  removeSupply,
  // eslint-disable-next-line import/named
  fetchSuppliesData
} from 'src/redux/slice/supply';
// auth
import { useAuthContext } from 'src/auth/hooks'
//
import { updateSupply } from '../server/user';

// ----------------------------------------------------------------------

export default function useSupplies() {
  const dispatch = useDispatch()

  const {allData, isLoading, error} = useSelector(state => state.supplies)

  const { user } = useAuthContext()
  const config = user?.configSelected?.id

  const onRefreshSupplies = useCallback(
    ({ idUser }) => {
      dispatch(fetchSuppliesData({ idUser, config }))
    },
    [config, dispatch],
  );

  const onInitSupplies = useCallback(
    ({ idUser }) => {
      onRefreshSupplies({ idUser })
    },
    [onRefreshSupplies],
  );

  const onResetSupplies = useCallback(
    () => {
      dispatch(resetSupplies());
    },
    [dispatch],
  );

  const onUpdateSupplies = useCallback(
    (data) => {
      dispatch(updateSupplies(data))
    },
    [dispatch],
  );

  const fullUpdateSupply = useCallback(
    async ({ data, idUser }) => {
      if(!data.histories?.[0]) data.histories = [{ event: "supply_created", date: new Date() }]
      console.log('fullUpdateSupply ', { idUser, config, data });
      const supplyUpdated = await updateSupply(idUser, config, data)
      console.log('supplyUpdated ', supplyUpdated);
      const { data: newSupply } = supplyUpdated
      if (newSupply){
        newSupply.products = newSupply.products.map((ps) => ({...ps.product, ...ps, product: undefined}))
        // console.log('newSupply', newSupply)
        onUpdateSupplies([newSupply])
      }
    },
    [config, onUpdateSupplies],
  );

  const onRemoveSupply = useCallback(
    (id) => {
      dispatch(removeSupply(id))
    },
    [dispatch],
  );

  const supplies = useMemo(() => allData.map((data) => {
    const createdAt = new Date(data.createdAt);
    const importedAt = new Date(data.importedAt);
    const lastUpdatedDate = new Date(data.lastUpdatedDate);
    const suppliedAt = data.suppliedAt && new Date(data.suppliedAt) || undefined;
    const histories = data.histories?.map(history => {
      const newHistory = {...history}
      newHistory.receiveDetail = newHistory.receiveDetail?.map(rd => {
        const newRd = {...rd}
        if(typeof newRd.product === 'string') newRd.product = data.products.find(x => x.id === rd.product)
        return newRd
      }) || []
      newHistory.id = newHistory._id || newHistory.id
      newHistory.date = new Date(newHistory.date)
      return newHistory
    }) || []
    const products = data.products.map(product => {
      const historiesFound = histories.filter(history => history.receiveDetail?.find(x => x.product?.id === product.id))
        .sort((a, b) => new Date(b.date) - new Date(a.date))
      const newProduct = {...product}
      newProduct.quantityReceived = historiesFound?.reduce((qty, hist) => {
        const recDetails = hist.receiveDetail.filter(x => x.product?.id === product?.id)
        const qtyRec = recDetails.reduce((prev, curr) => prev + curr.quantity, 0)
        return qty + qtyRec
      }, 0) || 0
      newProduct.remaining = newProduct.quantity - newProduct.quantityReceived;
      newProduct.closed = newProduct.remaining <= 0
      newProduct.historiesFound = historiesFound;
      if(historiesFound.length > 0)
        newProduct.suppliedAt = new Date(historiesFound[0].date)
      return newProduct
    })
    return { ...data, suppliedAt, createdAt, importedAt, lastUpdatedDate, histories, products };
  }), [allData])

  return useMemo(() => ({
    supplies,
    isLoading,
    isEmpty: !supplies.length,
    fieldsSearchable: ['name', 'id'],
    allEvents: ["supply_created", "supply_confirmed", "supply_partially_received", "supply_received"],
    error,
    //
    onRefreshSupplies,
    onInitSupplies,
    onResetSupplies,
    onUpdateSupplies,
    fullUpdateSupply,
    onRemoveSupply
  }), [error, isLoading, onInitSupplies, onRefreshSupplies, onRemoveSupply, onResetSupplies
    , onUpdateSupplies, fullUpdateSupply, supplies])
  ;
}
