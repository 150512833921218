// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomersByPage, getOneCustomer } from 'src/api/server/customer';

// ----------------------------------------------------------------------

export const fetchCustomersData = createAsyncThunk(
  'customers/fetchCustomersData',
  async ({ idUser, config, page, addData }) => {
    const customers = await getCustomersByPage(idUser, config, page);
    console.info('fetchCustomersData customers', customers);
    // eslint-disable-next-line no-restricted-syntax
    for(const customer of customers?.results||[]){
      // eslint-disable-next-line no-await-in-loop
      await addData(customer)
    }
    return customers;
  });

export const fetchOneCustomer = createAsyncThunk(
  'customers/fetchOneCustomer',
  async ({ idUser, config, id, addData }) => {
    const customer = await getOneCustomer(idUser, config, id)
    console.info('fetchOneCustomer customer', customer)
    addData(customer)
    return customer
  });

const initialState = { allData: [], isLoading: false, error: undefined, nextPage : 1 };

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetCustomers: (state, action) => {
      action.payload()
      state.allData = []
      state.nextPage = 1
    },
    updateCustomers: (state, action) => {
      const { data, addData } = action.payload;
      data.forEach(customer => {
        const customerFound = state.allData.find(cust => cust.id === customer?.id);
        if (customerFound) {
          Object.entries(customer).forEach(([key, value]) => {
            customerFound[key] = value;
          });
          addData(customer)
        }
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCustomersData.pending, state => {
        if (!state.allData[0]) {
          state.isLoading = true;
          state.error = undefined;
        }
      })
      .addCase(fetchCustomersData.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = undefined
        state.nextPage = action.payload.nextPage
        action.payload.results?.forEach(customer => {
          const customerFound = state.allData.find(x => x?.id === customer.id);
          const customerToPush = {
            id: customer.id,
            email: customer.email,
            company: customer.company,
            entityid: customer.entityid,
            lastName: customer.lastName,
            firstName: customer.firstName,
            phoneNumber: customer.phoneNumber
          }
          if (!customerFound) {
            customerToPush.importedAt = (new Date()).toLocaleString();
            customerToPush.lastUpdatedDate = (new Date()).toLocaleString();
            state.allData.push(customerToPush);
          } else {
            Object.entries(customerToPush).forEach(entry => {
              const [key, value] = entry;
              if (key !== 'importedAt' && key !== 'lastUpdatedDate') {
                customerFound[key] = value;
              }
            });
            customerFound.lastUpdatedDate = (new Date()).toLocaleString();
          }
        });
      })
      .addCase(fetchCustomersData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneCustomer.fulfilled, (state, action) => {
        const data = action.payload;
        const dataFound = state.allData.find(x => x?.id === data.id);
        const dataToPush = {
          id: data.id,
          email: data.email,
          company: data.company,
          entityid: data.entityid,
          lastName: data.lastName,
          firstName: data.firstName,
          phoneNumber: data.phoneNumber
        }
        if (!dataFound) {
          dataToPush.importedAt = (new Date()).toLocaleString();
          dataToPush.lastUpdatedDate = (new Date()).toLocaleString();
          state.allData.push(dataToPush);
        } else {
          Object.entries(dataToPush).forEach(entry => {
            const [key, value] = entry;
            if (key !== 'importedAt' && key !== 'lastUpdatedDate') dataFound[key] = value;
          });
          dataFound.lastUpdatedDate = (new Date()).toLocaleString();
        }
      });
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetCustomers,
  updateCustomers,
} = slice.actions;
